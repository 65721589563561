.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  & .socialNetworks {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4.75rem;

    & a {
      margin: 0 1.25rem;

      & > svg {
        height: 3.75rem;
        width: 3.75rem;
      }
    }
  }

  & .hamidantez {
    display: block;
    height: 4rem;
    margin: 0 auto;

    & > svg {
      height: 100%;
    }
  }
}

@media screen and (max-width: 1600px) {
  .container {
    & .socialNetworks {
      margin-bottom: 2.5rem;
    }
  }
}

@media screen and (max-width: 1440px) {
  .container {
    & .socialNetworks {
      margin-bottom: 2.5rem;
    }
  }
}

@media screen and (max-width: 1366px) {
  .container {
    & .socialNetworks {
      margin-bottom: 2rem;
    }
  }
}

@media screen and (max-width: 426px) {
  .container {
    & .socialNetworks {
      & a {
        & > svg {
          height: 4rem;
          width: 4rem;
        }
      }
    }
  }
}
