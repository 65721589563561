.wrapper {
  max-width: 1440px;
}

@media screen and (max-width: 1024px) {
  .headerFixed {
    position: fixed;
    top: 0;
    display: flex;
    width: 100vw;
    align-items: center;
    flex-direction: column;
    padding: 0 0 1rem;
    background: #232D46;
    z-index: 100;
    transition: top 0.15s;

    .logo {
      width: 12.25rem;
      margin-top: 9rem;
      display: block;
    }

    .logoTitle {
      height: 2.5rem;
      margin-top: 2rem;
      display: block;
    }
  }
}

@media screen and (max-width: 429px) {
  .headerFixed {
    position: fixed;
    top: 0;
    display: flex;
    width: 100%;
    height: 110px;
    align-items: center;
    flex-direction: column;
    background: #232D46;
    z-index: 100;
    transition: top 0.15s;

    .logo {
      width: 70px;
      margin-top: 30px;
      display: block;
    }

    .logoTitle {
      height: 11px;
      margin-top: 12px;
      display: block;
    }
  }
}
