@import "assets/scss/variables";

.main {
  flex-grow: 1;
  background: $blue-color;
  // padding: 20px 35px;
  position: relative;
  // overflow-x: hidden;

  @media (max-width: 850px) {
    // padding: 10px;
    // overflow: initial !important;
  }
}
