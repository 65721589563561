.swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 100%;
  background: #C4C4C4;
  opacity: 0.5;
  @at-root button#{&} {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    appearance: none;
  }
  .swiper-pagination-clickable & {
    cursor: pointer;
  }
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #C4C4C4;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 2px;
}

@media screen and (min-width: 429px) and (max-width: 1024px) {
  .swiper-pagination-bullet {
    width: 1.1rem;
    height: 1.1rem;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 0.25rem;
  }
}
