@import 'assets/scss/variables';

.btn {
  width: 240px;
  height: 50px;
  display: block;
  margin: auto;
  margin-top: 8px;
  border: 2.37433px solid $brown-color;
  box-sizing: border-box;
  box-shadow: 0px -8.31016px 7.123px rgba(0, 0, 0, 0.25), 0px 8.31016px 7.123px rgba(0, 0, 0, 0.25),
    8.31016px 0px 7.123px rgba(0, 0, 0, 0.25), -8.31016px 0px 7.123px rgba(0, 0, 0, 0.25);
  border-radius: 11.8717px;
  font-family: SFTextLight;
  font-size: 28.492px;
  color: $brown-color;
  &:active {
    background: #232d46;
    border: 3px solid #99763d;
    box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.25), 0px 5px 5px rgba(0, 0, 0, 0.25),
      5px 0px 5px rgba(0, 0, 0, 0.25), -5px 0px 5px rgba(0, 0, 0, 0.25);
    border-radius: 11.8717px;
  }
}

.wrapper {
  padding-top: 93px;
  max-width: 1158px;
  margin: 0 auto;
  padding-bottom: 94px;
  height: 100%;
  header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    span {
      text-align: center;
      color: #cc9933;
      font-size: 23px;
      font-family: SFTextLight;
      margin-top: 23px;
    }

    .logo {
      width: 120px;
      margin-top: 75px;
    }
  }
  h1 {
    font-size: 50px;
    line-height: 50px;
    font-family: 'SFTextRegular';
    letter-spacing: -0.05em;
    color: #b1b1b1;
    margin: 0;
  }
  h3 {
    font-family: 'SFTextRegular';
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.05em;
    color: #c4c4c4;
    margin-top: 17px;
  }

  .banner {
    display: flex;
    position: relative;
    // justify-content: flex-end;
    max-width: 1152px;
    height: 481px;
    margin: 0 auto;
    margin-top: 120px;
    background: #232d46;
    box-shadow: -8px 0px 15px rgba(0, 0, 0, 0.25), 8px 0px 15px rgba(0, 0, 0, 0.25),
      0px -8px 15px rgba(0, 0, 0, 0.25), 0px 8px 15px rgba(0, 0, 0, 0.25);
    // background: no-repeat url(../../assets/images/questionnaire/title-image.png) left;
    a {
      position: absolute;
      right: 20px;
      top: 20px;
    }
    img {
      width: 100%;
      height: 100%;
    }
    // .bunnerText {
    //   display: flex;
    //   font-family: 'SFTextRegular';
    //   flex-direction: column;
    //   justify-content: space-between;
    //   width: 515px;
    //   height: 481px;
    //   padding: 41px;
    //   h1 {
    //     font-family: 'SFTextRegular';
    //     font-size: 55px;
    //     color: #cccccc;
    //     span {
    //       color: #ff0000;
    //     }
    //   }
    //   p {
    //     width: 360px;
    //     font-family: 'SFTextRegular';
    //     font-size: 24px;
    //     color: #cccccc;
    //   }
    // }
  }
}

@media screen and (max-height: 899px) {
  .wrapper {
    height: 100%;
    max-height: 100%;
    padding-top: 5.75vh;
    padding-bottom: 0;
    overflow: hidden;

    .banner {
      margin-top: 9.75vh;
    }
  }
}

@media screen and (min-width: 429px) and (max-width: 1024px) {
  .wrapper {
    overflow: initial;

    header {
      display: none;
    }

    .banner {
      height: initial;
      margin: 12rem 3.5rem 0;
    }
  }
}

@media screen and (max-width: 429px) {
  .wrapper {
    padding: 93px 20px 80px 20px;
    margin-top: 58px;
    box-sizing: border-box;

    header {
      display: none;
    }

    .banner {
      margin-top: 0;
      height: initial;
    }
  }
}
