.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

@media screen and (max-width: 1024px) {
  html {
    font-size: 10px;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 7.5px;
  }
}

@media screen and (max-width: 429px) {
  html {
    font-size: 7.5px;
  }

  .wrapper {
    height: 100vh;
    height: -webkit-fill-available;
  }
}

@media screen and (max-width: 375px) {
  html {
    font-size: 6.6px;
  }
}

@media screen and (max-width: 325px) {
  html {
    font-size: 5.7px;
  }
}
