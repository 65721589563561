@import 'assets/scss/variables';

.btn {
  width: 170px;
  height: 40px;
  display: flex;
  justify-content: center;
  margin: 20px auto;
  border: 2.37433px solid $brown-color;
  box-sizing: border-box;
  box-shadow: 0px -8.31016px 7.123px rgba(0, 0, 0, 0.25), 0px 8.31016px 7.123px rgba(0, 0, 0, 0.25),
    8.31016px 0px 7.123px rgba(0, 0, 0, 0.25), -8.31016px 0px 7.123px rgba(0, 0, 0, 0.25);
  border-radius: 11.8717px;
  font-family: SFTextLight;
  font-size: 22px;
  line-height: 35px;
  letter-spacing: -0.03em;
  color: $brown-color;

  &:active {
    background: #232d46;
    border: 3px solid #99763d;
    color: #99763d;
    box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.25), 0px 5px 5px rgba(0, 0, 0, 0.25),
      5px 0px 5px rgba(0, 0, 0, 0.25), -5px 0px 5px rgba(0, 0, 0, 0.25);
  }
}

.container {
  width: 355px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: SFTextRegular;

  h3 {
    color: #c89b5a;
    text-align: center;
    margin-top: 24px;
    font-weight: 300;
    font-family: SFTextLight;
    font-size: 15px;
    letter-spacing: -0.03rem;
  }

  .logo {
    width: 103px;
    height: 51px;
    margin: 48px auto 0 auto;
  }
}

.sizeIcon {
  position: absolute;
  max-width: 80px;
  max-height: 70px;
  right: 20px;
  top: 18px;
}

.vehicleNumber {
  display: flex;

  // flex-direction: column;
  .flag {
    display: flex;
    flex-direction: column;

    img {
      width: 26px;
      height: 16px;
      margin-top: 4px;
    }

    span {
      font-size: 17.8868px;
      line-height: 21px;
      text-align: center;
      color: #bcbbc1;
      font-family: SFTextRegular;
      margin-top: 4px;
    }
  }
}

.mask {
  display: flex;
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  margin-left: 3px;

  input {
    width: 33px;
    height: 45px;
    font-size: 41.7358px;
    line-height: 50px;
    border: 0;
    margin: auto 3px;
    text-align: center;
    text-transform: uppercase;
    color: #8e8e93;
    background: #efefef;
    box-shadow: inset 0px 0.993711px 1.98742px rgba(0, 0, 0, 0.25);
    border-radius: 0.993711px;
  }

  span {
    margin-top: 15px;
    margin-right: 3px;
    color: #ffffff;
    font-family: SFTextBold;
  }
}

.main {
  max-width: 355px;
  max-height: 495px;
  padding: 18px;
  background: #2b354d;
  border-radius: 2px;
  margin-top: 33px;
  position: relative;

  .sadSmileIcon {
    margin-top: 48px;
  }

  .text {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.05em;
    color: #c4c4c4;
    margin-top: 40px;
  }
}


table {
  margin-top: 30px;
  border-collapse: collapse;
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: 50% 40%;
  .manat {
    img {
      margin-right: 5px;
      width: 19px;
    }
  }

  tr {
    height: 46px;

    &:not(:last-child) {
      border-bottom: 2px solid #232d46;
    }

    td {
      width: 50%;

      &:nth-child(1) {
        font-family: SFTextRegular;
        width: 100%;
        font-size: 17px;
        line-height: 20px;
        text-align: left;
        letter-spacing: -0.04em;
        color: #ffffff;
      }

      &:nth-child(3),
      &:nth-child(2) {
        font-family: SFTextLight;
        font-size: 16px;
        line-height: 19px;
        text-align: right;
        color: #dadada;
      }
    }
  }
}
.kFit {
  background-image: url(../../assets/images/payment/k-fit-bg.png);
}
.kMatik {
  background-image: url(../../assets/images/payment/k-matik-bg.png);
  background-size: 60%;
}

.textCenter {
  text-align: center;
}

h1 {
  font-size: 24px;
  line-height: 29px;
  margin-top: -3px;
  letter-spacing: -0.04em;
  color: #ffffff;
  font-family: SFTextBold;
}

.paymentTable {
  margin-top: 28px;

  tr {
    height: 44.5px;

    &:not(:first-child) {
      & td:nth-child(1) {
        width: auto !important;
      }
      & td:nth-child(2) {
        max-width: 100% !important;
        width: 100% !important;
      }
      & td:nth-child(3) {
        position: relative;

        &:before {
          position: absolute;
          content: '';
          top: 0;
          bottom: 0;
          left: -22px;
          margin: auto;
          width: 19px;
          height: 12px;
          background: no-repeat url(../../assets/images/payment/manat.png);
          background-size: contain;
        }
      }
    }
  }
}

.linkBlock {
  margin-top: 23px;
  justify-content: space-between;
  height: 86px;
  width: 100%;
  display: flex;

  img {
    height: 86px;
  }

  .social {
    display: flex;
    flex-wrap: wrap;
    width: 160px;
    justify-content: space-between;
    align-content: space-between;

    img {
      height: 36px;
    }
  }
}

.tableSuccess {
  margin-top: 37px;

  tr {
    height: 47px;
  }
}

.successTitle {
  text-align: center;
  font-family: SFTextMedium;
}

.failTitle {
  margin-top: 10px;
  font-family: SFTextMedium;
}

.failBtn {
  width: 212px;
  margin-top: 68px;
  margin-bottom: 0;
}

@media screen and (max-width: 429px) {
  .container {
    justify-content: flex-start;
    width: initial;
    min-height: 100vh;
    margin: 0 19px;
    padding-bottom: 19px;
  }

  .main {
    flex: 1;
    max-height: initial;
    max-width: 338px;
    margin: 36px auto 0;

    .text {
      font-size: 13.343px;
      line-height: 23px;
      letter-spacing: -0.05em;
    }
  }

  .mask {
    input {
      line-height: 42px;
      width: 13%;
      margin: 0 1.5px;
    }
  }

  h1 {
    font-size: 22.8738px;
    line-height: 27px;
    letter-spacing: -0.04em;
  }

  .btn {
    margin: 38px auto 20px;
  }
}
