@import 'assets/scss/variables';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 530px;
  box-sizing: border-box;
  font-family: SFTextRegular;

  .main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1200px;
    box-sizing: border-box;

    & h3 {
      color: #c89b5a;
      text-align: center;
      margin-top: 24px;
      font-weight: 300;
      font-family: SFTextLight;
      font-size: 15px;
      letter-spacing: -0.03rem;
    }

    & .logo {
      width: 103px;
      height: 51px;
      margin: 48px auto 0 auto;
    }

    & .mainTitle {
      margin: 5rem 0 8.8rem;
      text-align: center;
      font-size: 3.25rem;
      line-height: 3.9rem;
      font-weight: 400;
      font-family: SFTextRegular;
    }
  }

  .userActions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3.25rem;

    & > button {
      margin: 0 3.125rem;

      & > svg {
        width: 6.25rem;
        height: 6.25rem;
      }
    }
  }

  .appLinks {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.85rem 0;
    background: white;

    & > a {
      margin: 0 2.5rem;

      & > img {
        height: 4.35rem;
      }
    }
  }

  .surveySocialWrapper {
    flex: 1;
    //max-height: 381px;
    max-height: 23.8rem;
    background: #E5E5E5;
  }
}

@media screen and (max-width: 1600px) {
  html {
    font-size: 11.6px;
  }
}

@media screen and (max-width: 1440px) {
  html {
    font-size: 10.5px;
  }
}

@media screen and (max-width: 1366px) {
  html {
    font-size: 10px;
  }
}

@media screen and (max-width: 426px) {
  .container {
    .main {
      .logo {
        width: 70px;
        margin-top: 48px;
      }

      h3 {
        display: block;
        margin-top: 12px;
        font-size: 11px;
      }

      .mainTitle {
        margin: 7rem 0 5.5rem;
      }
    }

    .surveySocialWrapper {
      flex: none;
      //max-height: 381px;
      max-height: 23.8rem;
      padding: 6.25rem 0 4.68rem;
    }
  }
}
