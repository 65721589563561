.wrapper {
  height: 100%;
  max-height: 100%;
  position: relative;
  overflow: hidden;

  .wrapperBackground {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1745px;
    height: 100%;
    background-image: url('../../assets/images/hamidantez-full.svg');
    background-repeat: no-repeat;
    background-size: 1670px;
    text-align: center;
    transform: translate(-50%, 0);
  }

  .tabletBackground {
    display: none;
  }

  .wrapperInner {
    max-width: 1440px;
    margin: 0 auto;
    text-align: center;
    position: relative;

    .container {
      max-width: 1154px;
      margin: 0 auto;
      text-align: left;
      position: relative;

      .help {
        position: absolute;
        width: 120px;
        height: 120px;
        right: -14px;
        top: 78px;
        background-image: url(../../assets/images/help-icon.png);
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
      }

      .helpRu {
        background-image: url(../../assets/svg/help-ru.svg);
      }
      .helpEn {
        background-image: url(../../assets/svg/help-en.svg);
      }
      .helpAz {
        background-image: url(../../assets/svg/help-az.svg);
      }

      img {
        margin-top: 90px;
      }
      h1 {
        font-family: SFTextLight;
        font-size: 50px;
        line-height: 50px;
        letter-spacing: -0.08em;
      }

      h2 {
        font-family: SFTextLight;
        font-size: 50px;
        line-height: 50px;
        letter-spacing: -0.08em;
        color: #b1b1b1;
        margin-top: 54px;
      }

      p {
        font-family: SFTextLight;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.045em;
        color: #c4c4c4;
        margin-top: 39px;
      }

      .iconBlock {
        display: flex;
        justify-content: space-between;
        width: 1195px;
        margin-top: 97px;
        height: 270px;

        .icon {
          display: flex;
          flex-direction: column;

          .imgContainer {
            display: flex;
            align-items: center;
            height: 51px;

            img {
              margin-top: 0;
            }
          }

          span {
            width: 138px;
            margin-top: 20px;
            text-align: left;
            font-size: 20px;
            line-height: 28px;
            color: #c4c4c4;
            font-family: SFTextLight;
          }
        }
      }
    }
  }
}

@media screen and (max-height: 899px) {
  .wrapper {
    height: 100%;
    overflow: hidden;
  }
}

@media screen and (min-width: 1600px) {
  .wrapper {
    .wrapperBackground {
      background-position-x: center;
    }
  }
}

@media screen and (min-width: 429px) and (max-width: 1024px) {
  .wrapper {
    box-sizing: border-box;

    .wrapperIntroSlide {
      padding: 0 40px 7.5rem 46px;

      .wrapperBackground {
        height: 11rem;
        width: 100%;
        top: 0;
        left: 0;
        background-image: url('../../assets/images/hamidantez-full.svg');
        background-size: 127%;
        transform: initial;
        background-position-x: 52%;
      }
    }

    .wrapperListSlide {
      padding: 6rem 30px 7.5rem 40px;

      .wrapperBackground {
        display: none;
        height: 100%;
        width: 10.5rem;
        background-size: 100%;
        top: initial;
        bottom: 0;
        left: 0;
        background-image: url('../../assets/images/hamidantez-vertical.svg');
        transform: initial;
      }

      .tabletBackground {
        position: absolute;
        display: block;
        width: initial;
        height: 100%;
        top: 0;
        left: 0;
      }
    }

    .wrapperBackground {
      width: initial;
    }

    .wrapperInner {
      width: 71.5%;

      .container {
        max-width: initial;

        .iconBlock {
          display: flex;
          flex-direction: column;
          width: initial;
          height: initial;
          margin-top: initial;

          .icon {
            display: flex;
            flex-direction: row;
            align-items: center;

            &:nth-child(1) img { height: 8.32rem; }
            &:nth-child(2) img { height: 9rem; }
            &:nth-child(3) img { height: 9rem; }
            &:nth-child(4) img { height: 9rem; }
            &:nth-child(5) img { height: 4.72rem; }
            &:nth-child(6) img { height: 9rem; }
            &:nth-child(7) img { height: 9rem; }

            .imgContainer {
              width: 14rem;
              height: auto;
              margin-right: 5.85rem;

              img {
                width: 100%;
                margin-left: 0;
              }
            }

            span {
              flex: 1;
              width: initial;
              padding: 4rem 7rem 4rem 0;
              margin-top: 0;
              box-sizing: border-box;
              font-size: 3.3rem;
              line-height: 3.75rem;
            }
          }
        }

        img {
          height: 4.88rem;
          margin-top: 4.5rem;
          margin-left: -4.5rem;
        }

        h2 {
          width: 75%;
          margin-top: 12.7rem;
          font-size: 6rem;
          line-height: 6rem;
          font-weight: 300;
        }

        p {
          margin-top: 5rem;
          font-size: 3.5rem;
          line-height: 4.5rem;
        }
      }
    }
  }
}


@media screen and (max-width: 429px) {
  .wrapper {
    box-sizing: border-box;

    .wrapperIntroSlide {
      padding: 0 40px 0 46px;

      .wrapperBackground {
        height: 40px;
        width: 481px;
        top: 0;
        left: -55px;
        background-image: url('../../assets/images/hamidantez-full.svg');
        background-size: 100%;
        transform: initial;
      }
    }

    .wrapperListSlide {
      position: relative;
      padding: 0 30px 0 40px;
      margin-bottom: 25px;

      .wrapperBackground {
        display: block;
        height: 445px;
        width: 37px;
        background-size: 100%;
        top: initial;
        bottom: 0;
        left: 0;
        background-image: url('../../assets/images/hamidantez-vertical.svg');
        transform: initial;
      }
    }

    .wrapperBackground {
      width: initial;
    }

    .wrapperInner {
      .container {
        max-width: initial;

        .iconBlock {
          display: flex;
          flex-direction: column;
          width: initial;
          height: initial;
          margin-top: initial;

          .icon {
            display: flex;
            flex-direction: row;
            align-items: center;

            &:nth-child(1) img { height: 37px; }
            &:nth-child(2) img { height: 40px; }
            &:nth-child(3) img { height: 40px; }
            &:nth-child(4) img { height: 40px; }
            &:nth-child(5) img { height: 21px; }
            &:nth-child(6) img { height: 40px; }
            &:nth-child(7) img { height: 40px; }

            .imgContainer {
              width: 68px;
              height: auto;
              margin-right: 29px;

              img {
                width: 100%;
              }
            }

            span {
              flex: 1;
              width: initial;
              padding: 20px 0;
              margin-top: 0;
              box-sizing: border-box;
              font-size: 18px;
              line-height: 20px;
            }
          }
        }

        img {
          height: 16px;
          margin-top: 18px;
        }

        h2 {
          margin-top: 60px;
          font-size: 32px;
          line-height: 30px;
          font-weight: 300;
        }

        p {
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
  }
}
