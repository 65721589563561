.sliderBlock {
  position: absolute;
  bottom: 0;
  margin: 0 145px;
  width: calc(100% - 290px);
  filter: drop-shadow(0 0 3px black);

  & path#element {
    fill: #232D46;
  }
}

.sliderBlockHidden {
  display: none;
}

@media screen and (max-height: 899px) {
  .sliderBlock {
    margin: 0;
    bottom: 0;
  }
}

@media screen and (min-width: 429px) and (max-width: 1024px) {
  .sliderBlock {
    width: 89vw;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 100;
  }
}

@media screen and (max-width: 429px) {
  .sliderBlock {
    width: 88.5vw;
    margin: 0 auto;
    bottom: 25px;
    right: 0;
    left: 0;
    z-index: 100;
  }
}
