// Fonts
$main-font: 'Roboto';

// Colors
$main-text-color: #000000;
$second-text-color: #FFFFFF;
$white-color: #FFFFFF;
$brown-color: #C89B5A;
$error-color: #f44336;
$ok-color: #065535;
$ok-disabled-color: #CDDDD6;
$blue-color: #232D46;
$dashboard-color: #404152
