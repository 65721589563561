@import 'assets/scss/variables';

.wrapper {
  position: relative;
  max-width: 1440px;
  height: 900px;
  background-image: url('../../assets/images/car-background.svg');
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: 50% 58%;
  text-align: center;
  padding-bottom: 81px;
  padding-top: 20px;

  .logo {
    width: 120px;
    margin-top: 75px;
  }

  .logoTitle {
    display: block;
    color: $brown-color;
    font-size: 24px;
    font-family: SFTextLight;
    margin: 0 auto;
    margin-top: 19px;
  }

  @-webkit-keyframes left-text {
    0% {
      left: -500px;
    }

    10% {
      opacity: 0.5;
    }

    20% {
      opacity: 1;
    }

    100% {
      left: 0;
      opacity: 1;
    }
  }

  @keyframes left-text {
    0% {
      left: -500px;
    }

    10% {
      opacity: 0.5;
    }

    20% {
      opacity: 1;
    }

    100% {
      left: 0;
      opacity: 1;
    }
  }

  @-webkit-keyframes right-text {
    0% {
      left: 1500px;
    }

    10% {
      opacity: 0.5;
    }

    20% {
      opacity: 1;
    }

    100% {
      left: 384px;
      opacity: 1;
    }
  }

  @keyframes right-text {
    0% {
      left: 1500px;
    }

    10% {
      opacity: 0.5;
    }

    20% {
      opacity: 1;
    }

    100% {
      left: 384px;
      opacity: 1;
    }
  }


  .titleWrapper {
    width: 1185px;
    margin: 0 auto;
    margin-top: 135px;

    .leftTitle,
    .rightTitle {
      position: relative;
      font-family: SFTextLight;
      font-weight: 300;
      font-size: 48px;
      line-height: 48px;
      letter-spacing: -0.08em;
      color: #b1b1b1;

      b {
        font-family: SFTextRegular;
        letter-spacing: -0.06em;
        font-weight: 600;
      }
    }

    .leftTitle {
      max-width: 678px;
      text-align: right;
      opacity: 0;
      -webkit-animation-name: left-text;
              animation-name: left-text;
      -webkit-animation-duration: 2s;
              animation-duration: 2s;
      -webkit-animation-iteration-count: 1;
              animation-iteration-count: 1;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
      -webkit-animation-delay: 2s;
              animation-delay: 2s;
    }

    .rightTitle {
      width: 805px;
      text-align: left;
      opacity: 0;
      margin-top: 50px;
      -webkit-animation-name: right-text;
              animation-name: right-text;
      -webkit-animation-duration: 2s;
              animation-duration: 2s;
      -webkit-animation-iteration-count: 1;
              animation-iteration-count: 1;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
      -webkit-animation-delay: 2s;
              animation-delay: 2s;
    }
  }

  .languages {
    position: absolute;
    margin-top: 90px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    bottom: 80px;
    left: 0;
    right: 0;

    p {
      font-family: SFTextRegular;
      text-transform: lowercase;
      font-size: 34px;
      line-height: 49px;
      letter-spacing: -0.05em;
      color: #3A445F;
      &:hover {
        cursor: pointer;
      }
    }

    p:not(:nth-last-child(1)) {
      margin-right: 70px;
    }

    .currentLang {
      color: #756450;
    }
  }
}

@media screen and (max-height: 899px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 30px;
    background-position: 50% 68%;
    overflow: hidden;

    .logo {
      margin-top: 25px;
    }

    .titleWrapper {
      margin-top: 5.9vh;

      .rightTitle {
        margin-top: 6.25vh;
      }
    }

    .languages {
      position: relative;
      margin-top: 0;
      margin-bottom: 0;
      bottom: initial;
    }
  }
}

@media screen and (min-width: 429px) and (max-width: 1024px) {
  @keyframes right-text {
    0% {
      left: 1500px;
    }

    10% {
      opacity: 0.5;
    }

    20% {
      opacity: 1;
    }

    100% {
      left: 42.5%;
      opacity: 1;
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-size: 96%;
    background-position: 50% 55%;

    .titleWrapper {
      flex: 1;
      width: 100%;
      max-width: 100%;
      margin-top: 15rem;
      margin-bottom: initial;
      padding: 0.5rem 0;
      overflow: hidden;

      .leftTitle,
      .rightTitle {
        font-size: 4.9rem;
        line-height: 5rem;
        text-align: left;
        letter-spacing: -0.08em;
      }

      .leftTitle {
        width: 58%;
        max-width: initial;
        padding-left: 8rem;
        text-align: right;
      }

      .rightTitle {
        margin-top: 5rem;
        width: 57.5%;
        padding-right: 8rem;
        -webkit-animation-name: right-text;
        animation-name: right-text;
      }

      .sliderMobile {
        padding: 0 24px;
        height: 45vh;
      }
    }

    .languages {
      bottom: 6vh;
      z-index: 10;

      p {
        font-size: 3.5rem;
        line-height: 49px;
      }

      p:not(:nth-last-child(1)) {
        margin-right: 7rem;
      }
    }
  }
}

@media screen and (max-width: 429px) {
  .wrapper {
    width: 100%;
    height: 100%;
    background-size: 100%;
    background-position: 50% 63%;

    .logo {
      width: 70px;
      margin-top: 51px;
    }

    .logoTitle {
      height: 11px;
      margin-top: 12px;
    }

    .titleWrapper {
      flex: 1;
      width: 100%;
      margin-top: 23vh;
      margin-bottom: 10vh;

      .leftTitle,
      .rightTitle {
        font-size: 34px;
        line-height: 35px;
        text-align: left;
        letter-spacing: -0.08em;
      }

      .rightTitle {
        animation-name: initial;
        width: initial;
        opacity: 1;
        margin-top: initial;
      }

      .sliderMobile {
        padding: 0 24px;
        height: 45vh;
      }
    }

    .languages {
      bottom: 6vh;
      z-index: 10;

      p {
        font-size: 24px;
        line-height: 49px;
      }

      p:not(:nth-last-child(1)) {
        margin-right: 81px;
      }
    }
  }
}

@media screen and (max-width: 325px) {
  .wrapper {
    .titleWrapper {
      .leftTitle,
      .rightTitle {
        font-size: 29px;
        line-height: 30px;
      }
    }
  }
}
