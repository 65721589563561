body {
  padding: 0;
  margin: 0;
  background: #232d46;
}

#logo {
  margin: 83px auto 0;
  text-align: center;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}
#text-block {
  position: relative;
  max-width: 690px;
  margin-bottom: 50px;
}

#gear-block {
  position: absolute;
  display: flex;
  right: 297px;
  top: -48px;
}
h2 {
  /* max-width: 690px; */
  font-family: "SFTextLight", Helvetica, Arial, san-serif;
  font-weight: 100;
  font-size: 50px;
  line-height: 50px;
  letter-spacing: -0.08em;
  margin-bottom: 5px;
  color: #b1b1b1;
  text-align: end;
}
.bold-text {
  font-family: "SFTextRegular", Helvetica, Arial, san-serif;
  letter-spacing: -0.06em;
  font-weight: 500;
}

#main-section {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 200px;
}

#mobile {
  position: relative;
  margin-left: 40px;
  display: flex;
}
#short-number {
  position: absolute;
  bottom: 30px;
  left: 25px;

}
#qrcode {
  margin-top: 93px;
  margin-left: 28px;
}
#link-block {
  margin-left: 20px;
  display: flex;
  margin-bottom: 29px;
}
#store-block {
  width: 140px;
}
#short-logo {
  margin-left: 22px;
}
#appstore {
  margin-top: 10px;
}

.icon {
  width: 48px;
  height: 48px;
  margin: 5px;
  position: relative;
  z-index: 2;
}

footer {
  display: flex;
  position: relative;
  justify-content: center;
  margin-top: 184px;
  margin-bottom: 140px;
}
#social-bg {
  position: absolute;
  top: 15px;
}
@media (max-width: 1200px) {
  #main-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    flex-direction: column;
  }
  #text-block {
    padding: 0 20px;
  }
  #mobile {
    margin-top: 40px;
  }
  #link-block {
    margin-top: 20px;
  }
  footer {
    margin-top: 70px;
  }
}
@media (min-width: 700px) {
  h2 {
    font-style: 45px;
  }
}

@media (max-width: 420px) {
  #logo {
    width: 113px;
    margin-top: 15px;

    & > img {
      width: 100%;
    }
  }
  #main-section {
    display: block;
    width: 271px;
    margin: 72px auto 0;
    //margin-top: 72px;
    //margin-right: 52px;
    //margin-left: 52px;
  }
  #text-block {
    padding: 0;
  }
  h2 {
    font-size: 40px;
    line-height: 42px;
  }
  #gear-block {
    right: 196px;
    top: -50px;
  }
  #gear-block img {
    transform: rotate(180deg);
    width: 108px;
  }
  #mobile {
    //margin-top: 50px;
    margin: 0 auto 0;
  }
  #short-number {
    left: 23px;
    bottom: 90px;
  }
  #qrcode {
    margin-top: 26px;
    margin-left: 24px;
  }
  #link-block {
    //display: flex;
    //flex-direction: row;
    margin-top: -79px;
    align-items: flex-end;
    margin-left: initial;
  }
  #store-block {
    width: 100%;
    position: relative;
    z-index: 2;
  }
  #store-block img {
    width: 95px;
    height: 30px;
    background: #232d46;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.25), 4px 0px 4px rgba(0, 0, 0, 0.25),
    0px -4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  #short-logo {
    order: -1;
  }
  #short-logo img {
    width: 30px;
    margin-left: 0;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.25), 4px 0px 4px rgba(0, 0, 0, 0.25),
    0px -4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  #g-play {
    margin-left: 3px;
  }
  .icon {
    height: 36px;
    width: 36px;
    margin: 7px;
  }
  footer {
    margin-top: 55px;
    margin-bottom: 40px;
  }
  #social-bg {
    margin-top: 7px;
    width: 290px;
  }
}
