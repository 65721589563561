@import './variables';

* {
  outline: none !important;
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  &:before,
  &:after {
    box-sizing: border-box;
  }
}

body {
  font-family: $main-font;
  font-size: 14px;
  background-color: #ffffff;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
  font-family: $main-font;
  font-size: 14px;

  &::-ms-clear {
    display: none !important;
  }

  &::-ms-reveal {
    display: none !important;
  }
}

button {
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  font-family: $main-font;
  font-size: 14px;
}

main {
  display: block;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.fb_dialog iframe {
  left: 12px !important;
  right: initial !important;
}

.fb-customerchat iframe {
  right: initial !important;
  left: 4px !important;
  margin-right: initial !important;
  margin-left: 12px !important;
}

.fb_customer_chat_bounce_in_v2 {
  animation-duration: 300ms;
  animation-name: fb_bounce_in_v2_left !important;
  transition-timing-function: ease-in;
}

.fb_customer_chat_bounce_out_v2 {
  animation-duration: 300ms;
  animation-name: fb_bounce_out_v2_left !important;
  transition-timing-function: ease-in;
  animation-fill-mode: forwards;
}

@keyframes fb_bounce_in_v2_left {
  0% {
    opacity: 0;
    transform: scale(0, 0);
    transform-origin: bottom left;
  }
  50% {
    transform: scale(1.03, 1.03);
    transform-origin: bottom left;
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
    transform-origin: bottom left;
  }
}

@keyframes fb_bounce_out_v2_left {
  0% {
    opacity: 1;
    transform: scale(1, 1);
    transform-origin: bottom left;
  }
  100% {
    opacity: 0;
    transform: scale(0, 0);
    transform-origin: bottom left;
  }
}

//@font-face {
//  font-display: swap;
//  font-family: "SFTextLightItalic";
//  src: url("../fonts/SF-Pro-Text-LightItalic.otf");
//  font-weight: 200;
//}
