@import 'assets/scss/variables';

.wrapper {
  padding-top: 93px;
  max-width: 1158px;
  margin: 0 auto;
  padding-bottom: 94px;
  height: 100%;

  .informationWrapper {
    margin-top: 0;
    h1 {
      font-family: 'SFTextLight';
      font-size: 50px;
      line-height: 50px;
      letter-spacing: -0.08em;
      font-weight: 300;
      b {
        font-family: 'SFTextBold';
      }
    }
    .rewiew {
      display: flex;
      margin-top: 37px;
      .score {
        div {
          width: 105px;
          height: 100px;
          position: relative;
          margin-top: 19px;
          .img_like {
            position: absolute;
            top: 0;
            left: 0;
          }
          .img_dislike {
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
        span {
          display: block;
          width: 100px;
          font-family: 'SFTextBold';
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.05em;
          color: #6a6f7b;
        }
      }
      .formContainer {
        width: 100%;
        margin-left: 40px;

        form {
          display: flex;
          width: 100%;
          flex-direction: column;
          border-left: 1px solid rgba(255, 255, 255, 0.5);
          border-right: 1px solid rgba(255, 255, 255, 0.5);
          padding-left: 25px;
          label {
            font-family: 'SFTextBold';
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.05em;
            color: #6a6f7b;
          }
          input,
          textarea {
            background: inherit;
            border: none;
            font-family: 'SFTextLight';
            font-size: 20px;
            line-height: 22px;
            letter-spacing: -0.05em;
            color: #b1b1b1;
          }
          button {
            margin: 49px auto 0 auto;
          }
        }
      }
    }
    .copyright {
      display: flex;
      justify-content: space-between;
      //margin-top: 33px;
      align-items: flex-end;
      p {
        font-family: 'SFTextLight';
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.04em;
        color: #c4c4c4;
        margin-bottom: 13px;
      }

      .logoContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 50px;

        .logoLLoyds {
          width: 133px;
          margin-right: 45px;
          margin-bottom: 23px;
        }
      }

      .logoWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          width: 220px;
        }

        span {
          position: relative;
          font-family: 'SFTextBold';
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.13em;
          color: #b1b1b1;
          margin-right: 45px;

          &::after {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 1px;
            height: 46px;
            right: -19px;
            background-color: white;
          }
        }
      }
    }
  }

  .communicate {
    max-width: 1150px;
    margin-top: 0;
    height: 324px;
    // margin: 0 auto;
    display: flex;
    justify-content: space-between;
    background: no-repeat url(../../assets/images/questionnaire/mobile-frame.svg);
    .store {
      position: relative;
      h2 {
        font-family: 'SFTextLight';
        font-weight: 300;
        margin-left: 20px;
        margin-top: 46px;
        width: 575px;
        font-size: 50px;
        line-height: 50px;
        letter-spacing: -0.08em;
        color: #b1b1b1;
        b {
          font-family: 'SFTextBold';
        }
      }
      .linkBlock {
        display: flex;
        position: absolute;
        align-items: flex-start;
        bottom: 25px;
        margin-left: 25px;
      }
      .gPlay {
        margin-left: 23px;
      }
      .qrCode {
        margin-left: 23px;
        width: 109px;
      }
      .appStore {
        position: absolute;
        bottom: 0px;
        left: 67px;
      }
      .numberWrapper {
        margin-left: 25px;
        border-left: 1px solid #B3B3B3;
      }
      .number {
        margin-left: 25px;
      }
    }
    .social {
      display: flex;
      justify-content: flex-end;
      padding-right: 23px;
      margin-top: 50px;
      height: 100px;
      width: 410px;
      background: no-repeat url(../../assets/images/questionnaire/social-bg.svg) bottom;
      img {
        margin-left: 11px;
        &:active {
          -webkit-filter: invert(60%) sepia(63%) saturate(318%) hue-rotate(356deg) brightness(95%) contrast(89%);
          filter: invert(60%) sepia(63%) saturate(318%) hue-rotate(356deg) brightness(95%) contrast(89%);
        }
      }
    }
  }
}

@media screen and (max-height: 899px) {
  .wrapper {
    height: 100%;
    max-height: 100%;
    padding-top: 48px;
    padding-bottom: 48px;
    overflow: hidden;
  }
}

@media screen and (min-width: 429px) and (max-width: 1024px) {
  .wrapper {
    padding: 9.5rem 26px 6.5rem;

    .informationWrapper {
      .copyright {
        display: block;

        .logoContainer {
          align-items: center;
          margin-top: 10.5rem;

          span {
            font-family: 'SFTextBold';
            letter-spacing: 0.18em;
            color: #b1b1b1;
            font-size: 3.5rem;
            line-height: 3.5rem;
            text-align: center;
          }

          .logoLLoyds {
            width: 22rem;
            margin: 3rem 0 0;
          }

          .logoWrapper {
            margin-top: 4rem;

            img {
              width: 36rem;
            }
          }
        }
      }
    }

    .communicate {
      display: flex;
      flex-direction: column;
      height: initial;
      background: initial;

      .store {
        display: flex;
        flex-direction: column;
        width: 60%;
        margin: 0 auto 9.5rem;

        h2 {
          width: initial;
          margin: initial;
          font-size: 5.9rem;
          line-height: 7rem;
        }

        .linkBlock {
          position: relative;
          bottom: initial;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 6px;
          margin-left: 0;
          margin-top: 8.3rem;
          box-shadow: 0 5.5859px 5.5859px rgba(0, 0, 0, 0.25),
          0 -5.5859px 5.5859px rgba(0, 0, 0, 0.25),
          5.5859px 0px 5.5859px rgba(0, 0, 0, 0.25),
          -5.5859px 0px 5.5859px rgba(0, 0, 0, 0.25);
          border-radius: 9.77533px;
          box-sizing: border-box;

          a {
            display: flex;
            align-items: center;

            &:nth-child(1) { width: 13.357%; }
            &:nth-child(2) { width: 41.049%; }
            &:nth-child(3) { width: 41.049%; }
          }

          img {
            position: initial;
            margin: 0;
            width: 100%;
          }
        }
      }

      .contact {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 60%;
        margin: 0 auto;
        padding: 2.5rem 0;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);

        img {
          height: initial;
          width: 7rem;
          margin-right: 5.1rem;
        }

        p {
          flex: 1;
          font-family: 'SFTextLight';
          font-size: 3rem;
          line-height: 3.6rem;
          letter-spacing: -0.05em;
          color: #c4c4c4;
        }
      }

      .social {
        display: flex;
        justify-content: center;
        width: 100%;
        height: initial;
        padding: 0;
        margin-top: 9rem;
        background: initial;

        img {
          width: 7.7rem;
          margin: 0 1rem
        }
      }
    }
  }
}

@media screen and (max-width: 429px) {
  .wrapper {
    padding: 60px 26px;

    .informationWrapper {
      .copyright {
        display: block;

        .logoContainer {
          align-items: center;
          margin-top: 32px;

          span {
            font-family: 'SFTextBold';
            letter-spacing: 0.18em;
            color: #b1b1b1;
            font-size: 20px;
            line-height: 32px;
            text-align: center;
          }

          .logoLLoyds {
            width: 120px;
            margin: 25px 0 0;
          }

          .logoWrapper {
            margin-top: 25px;

            img {
              width: 200px;
            }
          }
        }
      }
    }

    .communicate {
      display: flex;
      flex-direction: column;
      height: initial;
      background: initial;

      .store {
        display: flex;
        flex-direction: column;

        h2 {
          width: initial;
          margin: initial;
          font-size: 35px;
          line-height: 40px;
        }

        .linkBlock {
          position: relative;
          bottom: initial;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 6px;
          margin-left: 0;
          margin-top: 22px;
          box-shadow: 0 5.5859px 5.5859px rgba(0, 0, 0, 0.25),
                      0 -5.5859px 5.5859px rgba(0, 0, 0, 0.25),
                      5.5859px 0px 5.5859px rgba(0, 0, 0, 0.25),
                      -5.5859px 0px 5.5859px rgba(0, 0, 0, 0.25);
          border-radius: 9.77533px;
          box-sizing: border-box;

          a {
            display: flex;
            align-items: center;

            &:nth-child(1) { width: 13.357%; }
            &:nth-child(2) { width: 41.049%; }
            &:nth-child(3) { width: 41.049%; }
          }

          img {
            position: initial;
            margin: 0;
            width: 100%;
          }
        }
      }

      .contact {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 44px;
        padding: 12px 0;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);

        img {
          height: 71px;
          margin-right: 27px;
        }

        p {
          flex: initial;
          font-family: 'SFTextLight';
          font-size: 16.5094px;
          line-height: 20px;
          letter-spacing: -0.05em;
          color: #c4c4c4;
        }
      }

      .social {
        display: flex;
        justify-content: center;
        width: 100%;
        height: initial;
        padding: 0;
        margin-top: 50px;
        background: initial;

        img {
          width: 40px;
          margin: 0 6px;
        }
      }
    }
  }
}
