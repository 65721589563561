@import 'assets/scss/variables';

.btn {
  width: 240px;
  height: 50px;
  border: 2.37433px solid $brown-color;
  box-sizing: border-box;
  box-shadow: 0px -8.31016px 7.123px rgba(0, 0, 0, 0.25), 0px 8.31016px 7.123px rgba(0, 0, 0, 0.25),
    8.31016px 0px 7.123px rgba(0, 0, 0, 0.25), -8.31016px 0px 7.123px rgba(0, 0, 0, 0.25);
  border-radius: 11.8717px;
  font-family: SFTextLight;
  font-style: normal;
  font-weight: 300;
  font-size: 28.492px;
  line-height: 42px;
  color: $brown-color;

  &:active {
    background: #232d46;
    border: 3px solid #99763d;
    color: #99763d;
    box-sizing: border-box;
    box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.25), 0px 5px 5px rgba(0, 0, 0, 0.25),
      5px 0px 5px rgba(0, 0, 0, 0.25), -5px 0px 5px rgba(0, 0, 0, 0.25);
    border-radius: 11.8717px;
  }
}

.wrapper {
  max-width: 1440px;
  height: 900px;
  padding-top: 85px;
  position: relative;
  margin: 0 auto;
  overflow-x: hidden;

  .headersWrapper {
    width: 862px;
    margin: 0 auto;

    .headers {
      display: flex;
      justify-content: space-between;
      width: 639px;
      color: #b1b1b1;

      h1 {
        font-family: SFTextLight;
        letter-spacing: -0.08em;
        font-weight: 300;
        font-size: 50px;
        line-height: 50px;
        max-width: 250px;
        text-align: end;
        margin: 0;
        color: #B1B1B1;
      }

      h2 {
        font-family: SFTextBold;
        letter-spacing: 0.15em;
        font-size: 24px;
        line-height: 42px;
        color: #808080;
      }
    }
  }

  .textBlock {
    width: 1205px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;

    p {
      width: 778px;
      font-family: SFTextLight;
      font-size: 20px;
      line-height: 24px;
      text-align: right;
      letter-spacing: -0.05em;
      color: #c4c4c4;
      margin-top: 17px;
      margin-left: 33px;
    }

    .logo {
      margin-left: 105px;
      display: flex;
    }
  }

  @-webkit-keyframes logo {
    0% {
      opacity: 1;
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }

    20% {
      opacity: 1;
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
    }

    21% {
      opacity: 0;
    }

    50% {
      -webkit-transform: translate(-945px, -597px) rotate(-90deg) scale(0.5);
              transform: translate(-945px, -597px) rotate(-90deg) scale(0.5);
      opacity: 0;
    }

    51% {
      opacity: 0.6;
      -webkit-transform: translate(-945px, -597px) rotate(-90deg) scale(0.5);
              transform: translate(-945px, -597px) rotate(-90deg) scale(0.5);
    }

    100% {
      -webkit-transform: translate(-991px, -160px) rotate(-90deg) scale(0.6);
              transform: translate(-991px, -160px) rotate(-90deg) scale(0.6);
      opacity: 1;
    }
  }

  @keyframes logo {
    0% {
      opacity: 1;
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }

    20% {
      opacity: 1;
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
    }

    21% {
      opacity: 0;
    }

    50% {
      -webkit-transform: translate(-945px, -597px) rotate(-90deg) scale(0.5);
              transform: translate(-945px, -597px) rotate(-90deg) scale(0.5);
      opacity: 0;
    }

    51% {
      opacity: 0.6;
      -webkit-transform: translate(-945px, -597px) rotate(-90deg) scale(0.5);
              transform: translate(-945px, -597px) rotate(-90deg) scale(0.5);
    }

    100% {
      -webkit-transform: translate(-991px, -160px) rotate(-90deg) scale(0.6);
              transform: translate(-991px, -160px) rotate(-90deg) scale(0.6);
      opacity: 1;
    }
  }

  @-webkit-keyframes logo-back {
    0% {
      -webkit-transform: translate(-991px, -160px) rotate(-90deg) scale(0.6);
              transform: translate(-991px, -160px) rotate(-90deg) scale(0.6);
      opacity: 1;
    }

    20% {
      opacity: 0.6;
      -webkit-transform: translate(-945px, -597px) rotate(-90deg) scale(0.5);
              transform: translate(-945px, -597px) rotate(-90deg) scale(0.5);
    }

    21% {
      -webkit-transform: translate(-945px, -597px) rotate(-90deg) scale(0.5);
              transform: translate(-945px, -597px) rotate(-90deg) scale(0.5);
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    51% {
      opacity: 1;
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }

  @keyframes logo-back {
    0% {
      -webkit-transform: translate(-991px, -160px) rotate(-90deg) scale(0.6);
              transform: translate(-991px, -160px) rotate(-90deg) scale(0.6);
      opacity: 1;
    }

    20% {
      opacity: 0.6;
      -webkit-transform: translate(-945px, -597px) rotate(-90deg) scale(0.5);
              transform: translate(-945px, -597px) rotate(-90deg) scale(0.5);
    }

    21% {
      -webkit-transform: translate(-945px, -597px) rotate(-90deg) scale(0.5);
              transform: translate(-945px, -597px) rotate(-90deg) scale(0.5);
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    51% {
      opacity: 1;
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }

  .slideText {
    img {
      -webkit-animation-name: logo;
              animation-name: logo;
      -webkit-animation-duration: 1s;
              animation-duration: 1s;
      -webkit-animation-iteration-count: 1;
              animation-iteration-count: 1;
      -webkit-animation-direction: normal;
              animation-direction: normal;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
      -webkit-animation-delay: 0.1s;
              animation-delay: 0.1s;
    }

    h2 {
      -webkit-transform: translate(0px, 67px);
              transform: translate(0px, 67px);
      transition: all 1s;
    }

    h1,
    p {
      -webkit-transform: translate(30px, 300px);
              transform: translate(30px, 300px);
      opacity: 0;
      transition: all 1s;
    }

    .btn {
      -webkit-transform: translate(0px, -70px);
              transform: translate(0px, -70px);
      opacity: 0;
      transition: opacity 1s, -webkit-transform 1s;
      transition: transform 1s, opacity 1s;
      transition: transform 1s, opacity 1s, -webkit-transform 1s;
    }
  }

  .backSlideText {
    img {
      -webkit-animation-name: logo-back;
              animation-name: logo-back;
      -webkit-animation-duration: 1s;
              animation-duration: 1s;
      -webkit-animation-iteration-count: 1;
              animation-iteration-count: 1;
      -webkit-animation-direction: normal;
              animation-direction: normal;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
    }

    h2 {
      -webkit-transform: translate(0px, 0px);
              transform: translate(0px, 0px);
      transition: all 0.8s;
    }

    h1,
    p {
      -webkit-transform: translate(0px, 0px);
              transform: translate(0px, 0px);
      transition: 0.8s;
    }

    .btn {
      -webkit-transform: translate(0px, 0px);
              transform: translate(0px, 0px);
      transition: -webkit-transform 0.8s;
      transition: transform 0.8s;
      transition: transform 0.8s, -webkit-transform 0.8s;
    }
  }

  .sizeBlock {
    display: flex;
    height: 262px;
    justify-content: space-between;
    max-width: 862px;
    margin: 0 auto;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    margin-top: 20px;

    img {
      width: 196px;
      position: absolute;
    }

    .plusBtn {
      position: absolute;
      right: -110px;
      bottom: -70px;

      img {
        width: 34px;
      }
    }

    .flip {
      .front {
        -webkit-transform: rotateY(180deg);
                transform: rotateY(180deg);
        transition: 1s;
        transition-delay: 0.5s;

      }

      .back {
        -webkit-transform: rotateY(360deg);
                transform: rotateY(360deg);
        transition: 1s;
        transition-delay: 0.5s;

      }
    }

    .backFlip {
      .front {
        -webkit-transform: rotateY(0deg);
                transform: rotateY(0deg);
        transition: 1s;
        transition-delay: 0.5s;

      }

      .back {
        -webkit-transform: rotateY(180deg);
                transform: rotateY(180deg);
        transition: 1s;
        transition-delay: 0.5s;

      }
    }

    .card {
      width: 260px;
      height: 100%;
      position: relative;
      display: flex;
      margin: -30px;
    }

    .front {
      position: absolute;
      width: 255px;
      z-index: 2;
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      img {
        position: absolute;
        width: 260px;
      }
    }

    .back {
      position: absolute;
      z-index: 1;
      width: 255px;
      height: 260px;
      -webkit-transform: rotateY(180deg);
              transform: rotateY(180deg);
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;

      img {
        position: absolute;
        width: 260px;
      }
    }
  }

  .footer {
    text-align: center;
    margin-top: 66px;
  }
  .shadowWrapperr {
    -webkit-filter: drop-shadow(0 0 3px black);
            filter: drop-shadow(0 0 3px black);
    position: absolute;
    top: 323px;
    width: 1152px;
    height: 482px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 100;
    overflow: hidden;
  }

  .slider {
    position: absolute;
    -webkit-clip-path: url(#myClip);
            clip-path: url(#myClip);
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: $blue-color;
    // box-shadow: -8px 0px 15px rgba(0, 0, 0, 0.25), 8px 0px 15px rgba(0, 0, 0, 0.25), 0px -8px 15px rgba(0, 0, 0, 0.25),
    //   0px 8px 15px rgba(0, 0, 0, 0.25);

    .minusBtn {
      z-index: 2;
      position: absolute;
      left: 18px;
      top: 18px;
    }

    .shareBtn {
      position: absolute;
      z-index: 2;
      right: 21px;
      top: 18px;
    }
    .pointBlock {
      position: absolute;
      display: flex;
      justify-content: space-between;
      bottom: 10px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 70px;
      height: 10px;
      z-index: 100;

      .point {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background: #c4c4c4;
        opacity: 0.5;
      }

      .active {
        opacity: 1;
      }
    }

    h2 {
      width: 88px;
      font-size: 34px;
      line-height: 35px;
      letter-spacing: -0.08em;
      color: #b1b1b1;
      position: relative;
      left: 15.5em;
      top: 6em;
    }
  }
}
.svgSquare {
  position: absolute;
  d: path('M 1152 482 L 0 482 C 0 382 0 242 0 6 L 1170 6 C 1155 154 1155 165 1155 297 Z');
}

// CSSTransition
.sliderEnter {
  .slider {
    .svgSquare {
      transition: 1s;
      -webkit-animation-name: sliderPath;
              animation-name: sliderPath;
      -webkit-animation-duration: 0.6s;
              animation-duration: 0.6s;
      -webkit-animation-timing-function: linear;
              animation-timing-function: linear;
      -webkit-animation-iteration-count: 1;
              animation-iteration-count: 1;
    }
  }
}

.sliderExitActive {
  .slider {
    .svgSquare {
      -webkit-animation-name: backSliderPath;
              animation-name: backSliderPath;
      -webkit-animation-duration: 0.6s;
              animation-duration: 0.6s;
      -webkit-animation-timing-function: linear;
              animation-timing-function: linear;
      -webkit-animation-iteration-count: 1;
              animation-iteration-count: 1;
    }
  }
}

@-webkit-keyframes backSliderPath {
  0% {
    d: path('M 1152 482 L 0 482 C 0 382 0 242 0 6 L 1170 6 C 1155 154 1155 165 1155 297 Z');
  }
  30% {
    d: path('M 1150 480 L 264 484 C 803 385 803 176 0 22 L 1152 22 C 1155 154 1155 165 1155 297 Z');
  }

  50% {
    d: path(
      'M 1150 480 L 726 484 C 1166 330 1122 143 473 22 L 1152 22 C 1155 154 1155 165 1155 297 Z'
    );
  }

  70% {
    d: path(
      'M 1150 480 L 1067 484 C 1166 330 1122 143 1045 154 L 1155 154 C 1155 154 1133 176 1144 352 Z'
    );
  }

  100% {
    d: path(
      'M 1150 480L 1155 484 C 1155 484 1155 484 1155 484 L 1155 484 C 1155 484 1155 484 1155 484 Z'
    );
  }
}

@keyframes backSliderPath {
  0% {
    d: path('M 1152 482 L 0 482 C 0 382 0 242 0 6 L 1170 6 C 1155 154 1155 165 1155 297 Z');
  }
  30% {
    d: path('M 1150 480 L 264 484 C 803 385 803 176 0 22 L 1152 22 C 1155 154 1155 165 1155 297 Z');
  }

  50% {
    d: path(
      'M 1150 480 L 726 484 C 1166 330 1122 143 473 22 L 1152 22 C 1155 154 1155 165 1155 297 Z'
    );
  }

  70% {
    d: path(
      'M 1150 480 L 1067 484 C 1166 330 1122 143 1045 154 L 1155 154 C 1155 154 1133 176 1144 352 Z'
    );
  }

  100% {
    d: path(
      'M 1150 480L 1155 484 C 1155 484 1155 484 1155 484 L 1155 484 C 1155 484 1155 484 1155 484 Z'
    );
  }
}

@-webkit-keyframes sliderPath {
  0% {
    d: path(
      'M 1150 480 L 1155 484 C 1155 484 1155 484 1155 484 L 1155 484 C 1155 484 1155 484 1155 484 Z'
    );
  }
  30% {
    d: path(
      'M 1150 480L 1067 484 C 1166 330 1122 143 1045 154 L 1155 154 C 1155 154 1133 176 1144 352 Z'
    );
  }
  50% {
    d: path(
      'M 1150 480 L 726 484 C 1166 330 1122 143 473 22 L 1152 22 C 1155 154 1155 165 1155 297 Z'
    );
  }
  70% {
    d: path('M 1150 480 L 264 484 C 803 385 803 176 0 22 L 1152 22 C 1155 154 1155 165 1155 297 Z');
  }
  100% {
    d: path('M 1152 482 L 0 482 C 0 382 0 242 0 6 L 1170 6 C 1155 154 1155 165 1155 297 Z');
  }
}

@keyframes sliderPath {
  0% {
    d: path(
      'M 1150 480 L 1155 484 C 1155 484 1155 484 1155 484 L 1155 484 C 1155 484 1155 484 1155 484 Z'
    );
  }
  30% {
    d: path(
      'M 1150 480L 1067 484 C 1166 330 1122 143 1045 154 L 1155 154 C 1155 154 1133 176 1144 352 Z'
    );
  }
  50% {
    d: path(
      'M 1150 480 L 726 484 C 1166 330 1122 143 473 22 L 1152 22 C 1155 154 1155 165 1155 297 Z'
    );
  }
  70% {
    d: path('M 1150 480 L 264 484 C 803 385 803 176 0 22 L 1152 22 C 1155 154 1155 165 1155 297 Z');
  }
  100% {
    d: path('M 1152 482 L 0 482 C 0 382 0 242 0 6 L 1170 6 C 1155 154 1155 165 1155 297 Z');
  }
}

@keyframes shake {
  0% {
    -webkit-transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(0px, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(2px, 1px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(2px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
  }
}
@keyframes shakeBack {
  0% {
    -webkit-transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(0px, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(2px, 1px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(2px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
  }
}

.shake {
  animation-name: shake;
  animation-duration: 0.5s;
  transform-origin: 50% 50%;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

.shakeBack {
  animation-name: shakeBack;
  animation-duration: 0.5s;
  transform-origin: 50% 50%;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

@media screen and (max-height: 899px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding-top: 3.75vh;
    padding-bottom: 5.75vh;
    overflow: hidden;

    .sizeBlock {
      width: 100%;
      margin-top: 0;
      margin-bottom: 6vh;
    }

    .footer {
      margin-top: 0;
    }
  }
}
