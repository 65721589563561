  // slides 1
  .slide1 {
    width: 100%;
    height: 100%;
    position: relative;

    .box1,
    .box2 {
      height: 298px;
      width: 298px;
      position: absolute;
      border-radius: 30%;
      background: #cccbcb;
      opacity: 0.1;
    }

    .box1 {
      left: 0;
      top: 0;
      -webkit-transform: rotate(10deg);
              transform: rotate(10deg);
    }

    .box2 {
      right: 0;
      bottom: 0;
      -webkit-transform: rotate(-20deg);
              transform: rotate(-20deg);
    }

    .text1 {
      position: absolute;
      font-family: SFTextRegular;
      width: 239px;
      color: #cccbcb;
      height: 277px;
      left: 50px;
      top: 70px;

      p {

        &:nth-child(1),
        &:nth-child(2) {
          width: 200px;
          font-size: 34.9308px;
          line-height: 64px;
          letter-spacing: -0.07em;
        }

        &:nth-child(2) {
          letter-spacing: -0.06em;
        }

        &:nth-child(3) {
          font-size: 44.1231px;
          line-height: 64px;
          letter-spacing: -0.06em;
        }

        &:nth-child(4) {
          font-size: 47.8px;
          line-height: 57px;
          letter-spacing: -0.06em;
        }

        &:nth-child(5) {
          letter-spacing: -0.06em;
          font-size: 34.7597px;
          line-height: 41px;
        }
      }
    }

    .text2 {
      font-family: SFTextRegular;
      position: absolute;
      color: #cccbcb;
      width: 212px;
      height: 256px;
      right: 15px;
      bottom: 51px;

      p {
        &:nth-child(1) {
          font-size: 42.2846px;
          line-height: 50px;
          letter-spacing: -0.08em;
        }

        &:nth-child(2) {
          font-size: 55.3804px;
          line-height: 66px;
          letter-spacing: -0.09em;
        }

        &:nth-child(3) {
          font-size: 54.2609px;
          line-height: 65px;
          letter-spacing: -0.06em;
        }

        &:nth-child(4) {
          font-size: 66.9541px;
          line-height: 80px;
          letter-spacing: -0.08em;
        }
      }
    }

    .img1 {
      height: 380px;
      width: 196px;
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-image: url(../../assets/images/ad-image.png);
      background-position: center;
      background-size: cover;
    }
  }

  // slide 2
  .slide2 {
    .box1 {
      position: absolute;
      width: 209px;
      height: 209px;
      border-radius: 30%;
      -webkit-transform: rotate(30deg);
              transform: rotate(30deg);
      left: -49px;
      top: -43px;
      background: #cccbcb;
      opacity: 0.1;
    }

    .box2 {
      position: absolute;
      width: 320px;
      height: 320px;
      border-radius: 30%;
      -webkit-transform: rotate(-20deg);
              transform: rotate(-20deg);
      left: 315px;
      top: 128px;
      background: #cccbcb;
      opacity: 0.1;
    }

    .box3 {
      position: absolute;
      width: 313px;
      height: 313px;
      border-radius: 30%;
      -webkit-transform: rotate(20deg);
              transform: rotate(20deg);
      right: 20px;
      top: 20px;
      background: #cccbcb;
      opacity: 0.1;
    }

    .text1 {
      position: absolute;
      width: 244px;
      height: 139px;
      left: 3.97%;
      right: 74.82%;
      word-spacing: 60px;
      top: 12.52%;
      bottom: 58.51%;
      font-family: SFTextRegular;
      font-size: 52.3612px;
      line-height: 42px;
      letter-spacing: -0.08em;
      color: #cccbcb;
    }

    .text2 {
      position: absolute;
      font-family: SFTextRegular;
      left: 36.79%;
      right: 50.17%;
      top: 31.74%;
      bottom: 35.06%;
      word-spacing: 60px;
      font-weight: 600;
      font-size: 27.5232px;
      line-height: 28px;
      text-align: right;
      letter-spacing: -0.06em;
      color: #cccbcb;
    }

    .text3 {
      position: absolute;
      width: 167px;
      height: 172px;
      font-family: SFTextRegular;
      left: 75.43%;
      right: 10.04%;
      top: 18.05%;
      bottom: 46.27%;
      font-weight: 600;
      font-size: 27.6429px;
      line-height: 28px;
      letter-spacing: -0.06em;
      color: #cccbcb;

      .manat img {
        height: 20.5px;
      }
    }

    .badges {
      position: absolute;
      left: 31.16%;
      right: 47.46%;
      top: 68.77%;
      bottom: 10.49%;
      height: 100px;
      width: 246px;
      background-image: url(../../assets/images/ad-small-badges.png);
      background-position: center;
      background-size: cover;
    }

    .creditCard {
      width: 100px;
      height: 83px;
      position: absolute;
      left: 86.98%;
      right: 4.36%;
      top: 28.84%;
      bottom: 53.94%;
      background-image: url(../../assets/images/ad-credit.png);
      background-position: center;
      background-size: cover;
    }
  }

  // slide 3
  .slide3 {
    width: 100%;
    height: 100%;
    position: relative;
    font-family: SFTextRegular;

    .box1,
    .box2,
    .box3,
    .box4 {
      position: absolute;
      width: 225px;
      height: 225px;
      border-radius: 30%;
      background: #cccbcb;
      opacity: 0.1;
    }

    .box1 {
      -webkit-transform: rotate(30deg);
              transform: rotate(30deg);
      -webkit-transform: rotate(-11deg);
              transform: rotate(-11deg);
      left: 0.66%;
      top: 9.66%;
    }

    .box2 {
      -webkit-transform: rotate(13deg);
              transform: rotate(13deg);
      left: 28.82%;
      top: 41.46%;
    }

    .box3 {
      -webkit-transform: rotate(-7deg);
              transform: rotate(-7deg);
      right: 28.55%;
      top: 3.92%;
    }

    .box4 {
      -webkit-transform: rotate(25deg);
              transform: rotate(25deg);
      right: 8.33%;
      top: 46.6%;
    }

    .text1 {
      position: absolute;
      width: 306px;
      height: 136px;
      left: 4.17%;
      top: 14.28%;
      font-size: 51.2849px;
      line-height: 41px;
      text-align: left;
      letter-spacing: -0.06em;
      color: #cccbcb;
    }

    .text2 {
      position: absolute;
      width: 129px;
      height: 86px;
      left: 27%;
      top: 49.38%;
      word-spacing: 50px;
      font-size: 27.6429px;
      line-height: 28px;
      text-align: right;
      letter-spacing: -0.06em;
      color: #cccbcb;
    }

    .text3 {
      position: absolute;
      width: 160px;
      height: 138px;
      left: 61.02%;
      top: 10.17%;
      font-size: 27.6px;
      line-height: 28px;
      letter-spacing: -0.06em;
      color: #cccbcb;
    }

    .text4 {
      position: absolute;
      right: 21%;
      top: 50.8%;
      font-size: 27.5006px;
      line-height: 28px;
      text-align: right;
      letter-spacing: -0.05em;
      color: #cccbcb;
    }

    .img1 {
      position: absolute;
      width: 95px;
      height: 133px;
      left: 41.32%;
      top: 45.85%;
      background-image: url(../../assets/images/ad-car-service-icon.svg);
      background-position: center;
      background-size: cover;
    }

    .img2 {
      width: 85px;
      height: 73.55px;
      position: absolute;
      left: 51.82%;
      top: 10.37%;
      background-image: url(../../assets/images/ad-medicine-icon.svg);
      background-position: center;
      background-size: cover;
    }

    .img3 {
      width: 156.77px;
      height: 141.25px;
      position: absolute;
      background-image: url(../../assets/images/ad-car-accident-icon.svg);
      background-position: center;
      background-size: cover;
      right: 4.33%;
      top: 51.6%;
    }
  }

  // slide 4
  .slide4 {
    color: #cccbcb;
    font-family: SFTextRegular;

    .box1,
    .box2,
    .box3 {
      position: absolute;
      background: #cccbcb;
      opacity: 0.1;
      border-radius: 30%;
    }

    .box1 {
      width: 226px;
      height: 232px;
      left: -2.68%;
      top: 29.41%;
      -webkit-transform: rotate(75deg);
              transform: rotate(75deg);
    }

    .box2 {
      width: 223px;
      height: 221px;
      left: 55.2%;
      top: -2.72%;
      -webkit-transform: rotate(61deg);
              transform: rotate(61deg);
    }

    .box3 {
      width: 304px;
      height: 316px;
      right: 2.48%;
      bottom: -19.71%;
      -webkit-transform: rotate(15deg);
              transform: rotate(15deg);
    }

    .text1 {
      width: 254px;
      height: 196px;
      position: absolute;
      left: 1.74%;
      top: 36.02%;
      text-align: center;

      p {
        &:nth-child(1) {
          font-size: 43.4265px;
          line-height: 52px;
          letter-spacing: -0.08em;
        }

        &:nth-child(2) {
          font-size: 53.8488px;
          line-height: 52px;
          letter-spacing: -0.07em;
        }

        &:nth-child(3) {
          font-size: 90.3271px;
          line-height: 87px;
          letter-spacing: -0.03em;
        }
      }
    }

    .img1 {
      width: 196px;
      height: 380px;
      position: absolute;
      left: 29.69%;
      top: 10.37%;
      background-image: url(../../assets/images/ad-image.png);
      background-position: center;
      background-size: cover;
    }

    .text2 {
      width: 230px;
      height: 184px;
      position: absolute;
      right: 23.14%;
      top: 9.96%;
      font-size: 30.3689px;
      line-height: 32px;
      letter-spacing: -0.07em;
    }

    .contact {
      position: absolute;
      width: 300px;
      height: 131px;
      right: 50px;
      bottom: 50px;

      .linkTitle {
        position: absolute;
        font-size: 22.2705px;
        line-height: 40px;
        letter-spacing: -0.06em;
        color: #cccbcb;
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
        left: -45px;
        top: 46px;

        span {
          opacity: 0.5;
        }
      }

      .code {
        position: absolute;
        width: 126.72px;
        height: 126.72px;
        left: 62px;
        background-image: url(../../assets/images/ad-qr-code.png);
        background-position: center;
        background-size: cover;
      }

      .phoneNumber {
        position: absolute;
        width: 69.4px;
        height: 126.6px;
        right: 0px;
        background-image: url(../../assets/images/ad-call.png);
        background-position: center;
        background-size: cover;
      }

      .callTitle {
        position: absolute;
        font-size: 20.0434px;
        line-height: 36px;
        letter-spacing: -0.07em;
        color: #cccbcb;
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
        right: 19px;
        top: 48px;

        span {
          opacity: 0.5;
        }
      }

      .store {
        position: absolute;
        width: 17.85px;
        height: 127px;
        left: 37px;
        background-image: url(../../assets/images/ad-store-icon.svg);
        background-position: center;
        background-size: cover;
      }
    }
  }
