.flex {
  display: flex;
  li {
    padding-right: 49px;
    font-size: 12px;
    display: inline-table;
  }
}

.dot {
  height: 17px;
  width: 17px;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  display: block;
}

.dotValue {
  color: white;
  font-size: 13px;
  padding-left: 24px;
  padding-top: 12px;
}

.recharts-wrapper .recharts-cartesian-grid-vertical line:last-child
{
  stroke-opacity: 0;
}

.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child,
.recharts-wrapper .recharts-cartesian-grid-horizontal line:nth-last-child(2)
{
  stroke-opacity: 0;
  padding-left: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding-right: 10px;
}

.recharts-cartesian-axis-line {
  fill: rgb(209, 209, 214);
}

.leaflet-control-container .leaflet-routing-container-hide {
  display: none;
}
