@import 'assets/scss/variables';

.container {
  display: flex;
  flex-direction: column;
  //justify-content: center;
  //flex-direction: column;
  height: 100%;
  min-height: 530px;
  box-sizing: border-box;
  font-family: SFTextRegular;

  .main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1200px;
    box-sizing: border-box;

    & h3 {
      color: #c89b5a;
      text-align: center;
      margin-top: 24px;
      font-weight: 300;
      font-family: SFTextLight;
      font-size: 15px;
      letter-spacing: -0.03rem;
    }

    & .logo {
      width: 103px;
      height: 51px;
      margin: 7.5rem auto 0 auto;
    }

    & .mainTitle {
      margin: 5rem 0 8.8rem;
      text-align: center;
      font-size: 3.25rem;
      line-height: 3.9rem;
      font-weight: 400;
      font-family: SFTextRegular;
    }

    & .reactImage {
      display: block;
      height: 3.5rem;
      margin: 8.25rem auto 0;
    }
  }

  .surveySocialWrapper {
    flex: 1;
    max-height: 23.8rem;
    //padding: 6.25rem 0 4.68rem;
  }
}

@media screen and (max-width: 426px) {
  .container {
    .surveySocialWrapper {
      max-height: 23.8rem;
    }

    .main {
      font-size: 11px;

      .logo {
        width: 70px;
        margin-top: 79px;
      }

      h3 {
        display: block;
        margin-top: 12px;
        font-size: 11px;
      }

      .mainTitle {
        margin: 7rem 0 5.5rem;
      }
    }

    .surveySocialWrapper {
      flex: 1;
      //max-height: 381px;
      max-height: 23.8rem;
      //padding: 6.25rem 0 4.68rem;
    }
  }
}
