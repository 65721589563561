@import 'assets/scss/variables';

.btn {
  width: 240px;
  height: 50px;
  border: 2.37433px solid $brown-color;
  box-sizing: border-box;
  box-shadow: 0px -8.31016px 7.123px rgba(0, 0, 0, 0.25), 0px 8.31016px 7.123px rgba(0, 0, 0, 0.25),
    8.31016px 0px 7.123px rgba(0, 0, 0, 0.25), -8.31016px 0px 7.123px rgba(0, 0, 0, 0.25);
  border-radius: 11.8717px;
  font-family: SFTextLight;
  font-size: 28.492px;
  line-height: 42px;
  color: $brown-color;

  &:active {
    background: #232d46;
    border: 3px solid #99763d;
    color: #99763d;
    box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.25), 0px 5px 5px rgba(0, 0, 0, 0.25),
      5px 0px 5px rgba(0, 0, 0, 0.25), -5px 0px 5px rgba(0, 0, 0, 0.25);
  }
}

.wrapper {
  max-width: 1440px;
  height: 900px;
  position: relative;
  padding-top: 80px;
  margin: 0 auto;
  width: 100%;

  .inner {
    position: relative;
  }

  .main {
    display: flex;
    position: relative;
    max-width: 964px;
    margin: 0 auto;
    margin-top: 60px;
    padding-left: 30px;

    .logo {
      position: absolute;
      left: -90px;
    }

    .textBlock {
      h1 {
        font-family: SFTextLight;
        font-size: 50px;
        font-weight: 300;
        margin-left: 96px;
        margin-top: 0px;
        line-height: 50px;
        letter-spacing: -0.08em;
        color: #b1b1b1;
      }

      p {
        width: 859px;
        font-family: SFTextLight;
        font-size: 20px;
        line-height: 24px;
        color: #c4c4c4;
        margin-top: 40px;
        margin-left: 97px;
        letter-spacing: -0.04em;
        span[title] {
          width: 17px;
          height: 14px;
          display: inline-block;
          background-image: url(../../assets/images/manat-limited.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }

        .manat {
          width: 17px;
          height: 14px;
        }
      }
    }
  }

  h2 {
    font-family: SFTextBold;
    letter-spacing: 0.2em;
    width: 867px;
    margin: 0 auto;
    color: #808080;
    font-size: 24px;
    line-height: 42px;
  }

  .slideText {
    .logo {
      -webkit-transform: translate(0px, -98px);
      transform: translate(0px, -98px);
      transition: all 1s;

      img {
        width: 133px;
      }
    }

    h2 {
      -webkit-transform: translate(-2px, 69px);
      transform: translate(-2px, 69px);
      transition: all 1s;
    }

    h1,
    p {
      -webkit-transform: translate(10px, 150px);
      transform: translate(10px, 150px);
      opacity: 0;
      transition: all 1s;
    }

    .btnWrapper {
      -webkit-transform: translate(0, -10px);
      transform: translate(0, -10px);
      transition: -webkit-transform 1s;
      transition: transform 1s;
      transition: transform 1s, -webkit-transform 1s;
    }
  }

  .backSlideText {
    .logo {
      -webkit-transform: translate(0px, 0px);
      transform: translate(0px, 0px);
      transition: all 0.8s;
    }

    h2 {
      -webkit-transform: translate(0px, 0px);
      transform: translate(0px, 0px);
      transition: all 0.8s;
    }

    h1,
    p {
      -webkit-transform: translate(0px, 0px);
      transform: translate(0px, 0px);
      transition: 0.8s;
    }

    .btnWrapper {
      -webkit-transform: translate(0px, 0px);
      transform: translate(0px, 0px);
      transition: -webkit-transform 0.8s;
      transition: transform 0.8s;
      transition: transform 0.8s, -webkit-transform 0.8s;
    }
  }

  .sizeBlock {
    display: flex;
    height: 262px;
    justify-content: space-between;
    max-width: 862px;
    margin: 0 auto;
    -webkit-perspective: 1000px;
    perspective: 1000px;
    margin-top: 20px;

    img {
      width: 196px;
      position: absolute;
    }

    .plusBtn {
      position: absolute;
      right: -110px;
      bottom: -70px;

      img {
        width: 34px;
      }
    }

    .flip {
      .front {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
        transition: 1s;
        transition-delay: 0.5s;

      }

      .back {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
        transition: 1s;
        transition-delay: 0.5s;

      }
    }

    .backFlip {
      .front {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
        transition: 1s;
        transition-delay: 0.5s;

      }

      .back {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
        transition: 1s;
        transition-delay: 0.5s;

      }
    }

    .card {
      //width: 260px;
      height: 100%;
      position: relative;
      display: flex;
      margin: 0 auto;
    }

    .front {
      position: absolute;
      //width: 255px;
      z-index: 2;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      img {
        position: absolute;
        //width: 260px;
      }
    }

    .back {
      position: absolute;
      z-index: 1;
      //width: 255px;
      //height: 260px;
      -webkit-transform: rotateY(180deg);
      transform: rotateY(180deg);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;

      img {
        position: absolute;
        //width: 260px;
      }
    }
  }
}

.btbBlock {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;

  .plusBtn {
    position: absolute;
    right: 145px;
    bottom: 0;
  }
}

.shadowWrapperr {
  //-webkit-filter: drop-shadow(0 0 3px black);
  //filter: drop-shadow(0 0 3px black);
  position: absolute;
  bottom: 0;
  width: 1152px;
  height: 482px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 100;
  opacity: 0;
  overflow: hidden;
}

.minusBtn {
  position: absolute;
  z-index: 2;
  left: 18px;
  top: 18px;
}

.shareBtn {
  position: absolute;
  z-index: 2;
  right: 21px;
  top: 18px;
}

.slider {
  position: absolute;
  //-webkit-clip-path: url(#myClip);
  //clip-path: url(#myClip);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  //background-color: $blue-color;
  // box-shadow: -8px 0px 15px rgba(0, 0, 0, 0.25), 8px 0px 15px rgba(0, 0, 0, 0.25),
  // 0px -8px 15px rgba(0, 0, 0, 0.25), 0px 8px 15px rgba(0, 0, 0, 0.25);

  img {
    width: 100%;
  }

  .pointBlock {
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 70px;
    height: 10px;
    z-index: 100;

    .point {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: #c4c4c4;
      opacity: 0.5;
    }

    .active {
      opacity: 1;
    }
  }
}
.svgSquare {
  position: absolute;
  // d: path('M 1152 470 L 0 482 C 0 352 0 242 0 22 L 1152 22 C 1155 154 1155 165 1155 297 Z')
  d: path('M 1152 482 L 0 482 C 0 382 0 242 0 6 L 1170 6 C 1155 154 1155 165 1155 297 Z');
}

// CSSTransition
.sliderEnter {
  opacity: 0;

  .slider {
    .svgSquare {
      transition: 1s;
      -webkit-animation-name: sliderPath;
      animation-name: sliderPath;
      -webkit-animation-duration: 0.6s;
      animation-duration: 0.6s;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-iteration-count: 1;
      animation-iteration-count: 1;
    }
  }
}

.sliderEnterActive {
  opacity: 0;
}

.sliderEnterDone {
  opacity: 1;
  transition: opacity 0.2s;
}

.sliderExitActive {
  .slider {
    .svgSquare {
      -webkit-animation-name: backSliderPath;
      animation-name: backSliderPath;
      -webkit-animation-duration: 0.6s;
      animation-duration: 0.6s;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-iteration-count: 1;
      animation-iteration-count: 1;
    }
  }
}

@-webkit-keyframes backSliderPath {
  0% {
    // d: path('M 1150 480 L 0 490 C 0 352 0 242 0 22 L 1152 22 C 1155 154 1155 165 1155 297 Z');
    d: path('M 1152 482 L 0 482 C 0 382 0 242 0 6 L 1170 6 C 1155 154 1155 165 1155 297 Z');
  }
  30% {
    d: path('M 1150 480 L 264 484 C 803 385 803 176 0 22 L 1152 22 C 1155 154 1155 165 1155 297 Z');
  }

  50% {
    d: path(
      'M 1150 480 L 726 484 C 1166 330 1122 143 473 22 L 1152 22 C 1155 154 1155 165 1155 297 Z'
    );
  }

  70% {
    d: path(
      'M 1150 480 L 1067 484 C 1166 330 1122 143 1045 154 L 1155 154 C 1155 154 1133 176 1144 352 Z'
    );
  }

  100% {
    d: path(
      'M 1150 480L 1155 484 C 1155 484 1155 484 1155 484 L 1155 484 C 1155 484 1155 484 1155 484 Z'
    );
  }
}

@keyframes backSliderPath {
  0% {
    // d: path('M 1150 480 L 0 490 C 0 352 0 242 0 22 L 1152 22 C 1155 154 1155 165 1155 297 Z');
    d: path('M 1152 482 L 0 482 C 0 382 0 242 0 6 L 1170 6 C 1155 154 1155 165 1155 297 Z');
  }
  30% {
    d: path('M 1150 480 L 264 484 C 803 385 803 176 0 22 L 1152 22 C 1155 154 1155 165 1155 297 Z');
  }

  50% {
    d: path(
      'M 1150 480 L 726 484 C 1166 330 1122 143 473 22 L 1152 22 C 1155 154 1155 165 1155 297 Z'
    );
  }

  70% {
    d: path(
      'M 1150 480 L 1067 484 C 1166 330 1122 143 1045 154 L 1155 154 C 1155 154 1133 176 1144 352 Z'
    );
  }

  100% {
    d: path(
      'M 1150 480L 1155 484 C 1155 484 1155 484 1155 484 L 1155 484 C 1155 484 1155 484 1155 484 Z'
    );
  }
}

@-webkit-keyframes sliderPath {
  0% {
    d: path(
      'M 1150 480 L 1155 484 C 1155 484 1155 484 1155 484 L 1155 484 C 1155 484 1155 484 1155 484 Z'
    );
  }
  30% {
    d: path(
      'M 1150 480L 1067 484 C 1166 330 1122 143 1045 154 L 1155 154 C 1155 154 1133 176 1144 352 Z'
    );
  }
  50% {
    d: path(
      'M 1150 480 L 726 484 C 1166 330 1122 143 473 22 L 1152 22 C 1155 154 1155 165 1155 297 Z'
    );
  }
  70% {
    d: path('M 1150 480 L 264 484 C 803 385 803 176 0 22 L 1152 22 C 1155 154 1155 165 1155 297 Z');
  }
  100% {
    // d: path('M 1150 480 L 0 490 C 0 352 0 242 0 22 L 1152 22 C 1155 154 1155 165 1155 297 Z');
    d: path('M 1152 482 L 0 482 C 0 382 0 242 0 6 L 1170 6 C 1155 154 1155 165 1155 297 Z');
  }
}

@keyframes sliderPath {
  0% {
    d: path(
      'M 1150 480 L 1155 484 C 1155 484 1155 484 1155 484 L 1155 484 C 1155 484 1155 484 1155 484 Z'
    );
  }
  30% {
    d: path(
      'M 1150 480L 1067 484 C 1166 330 1122 143 1045 154 L 1155 154 C 1155 154 1133 176 1144 352 Z'
    );
  }
  50% {
    d: path(
      'M 1150 480 L 726 484 C 1166 330 1122 143 473 22 L 1152 22 C 1155 154 1155 165 1155 297 Z'
    );
  }
  70% {
    d: path('M 1150 480 L 264 484 C 803 385 803 176 0 22 L 1152 22 C 1155 154 1155 165 1155 297 Z');
  }
  100% {
    // d: path('M 1150 480 L 0 490 C 0 352 0 242 0 22 L 1152 22 C 1155 154 1155 165 1155 297 Z');
    d: path('M 1152 482 L 0 482 C 0 382 0 242 0 6 L 1170 6 C 1155 154 1155 165 1155 297 Z');
  }
}

@keyframes shake {
  0% {
    -webkit-transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(0px, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(2px, 1px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(2px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
  }
}
@keyframes shakeBack {
  0% {
    -webkit-transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(0px, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(2px, 1px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(2px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
  }
}

.shake {
  animation-name: shake;
  animation-duration: 0.5s;
  transform-origin: 50% 50%;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

.shakeBack {
  animation-name: shakeBack;
  animation-duration: 0.5s;
  transform-origin: 50% 50%;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

@media screen and (max-height: 899px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding-top: 4.375vh;
    padding-bottom: 5.625vh;
    overflow: hidden;

    .inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      width: 100%;
    }

    .main {
      margin-top: 0;
      margin-bottom: 10px;
    }

    .sizeBlock {
      width: 100%;
      height: 265px;
      margin-top: 0;
      margin-bottom: 10px;
      -webkit-perspective: 1000px;
      perspective: 1000px;
      transform-style: preserve-3d;

      & > div.swiper-wrapper {
        -webkit-perspective: 1000px;
        perspective: 1000px;
        transform-style: preserve-3d;
      }
    }

    .btbBlock {
      .plusBtn {
        right: 145px;
        bottom: 0;
      }
    }
  }

  .shadowWrapperr {
    top: initial;
    bottom: 0;
  }
}

@media screen and (max-width: 1440px) {
  .shadowWrapperr {
    height: 33.3vw;
    width: calc(100% - 290px);
  }
}

@media screen and (min-width: 429px) and (max-width: 1024px) {
  .btn {
    font-size: 2.75rem;
    line-height: 4rem;
    width: 24.2rem;
    height: 4.8rem;
    background: $blue-color;
  }

  .shadowWrapperr {
    height: 106vw;
    width: 71.5vw;
  }

  .wrapperPagination {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;

    .customPagination {
      position: absolute;
      display: flex;
      top: -87px;

      & > span {
        margin: 0 2px;
      }
    }
  }

  .wrapper {
    height: 100rem;
    padding: 0;
    margin-bottom: 7vh;

    .inner {
      height: 100%;
    }

    .main {
      display: flex;
      align-items: center;
      max-width: 100%;
      width: 100%;
      padding: 0;
      margin-top: 48px;
      margin-bottom: 12.5rem;
      z-index: 1;

      .logo {
        position: relative;
        left: 0;

        img {
          width: 17.5rem;
          margin-left: 2.5rem;
        }

        h2 {
          position: absolute;
          left: 0;
          font-size: 1.9rem;
          line-height: 11px;
          writing-mode: tb-rl;
          transform: rotate(-180deg);
          letter-spacing: 0.04em;
        }

        h2.sloganDescription {
          top: 24.4rem;
          font-size: 3rem;
          line-height: 42px;
          left: 3.5rem;
        }
      }

      .logoDescription {
        img {
          margin-left: 26.25rem;
        }
      }

      .textBlock {
        h1 {
          font-size: 6rem;
          line-height: 5rem;
          margin-left: 1rem;
          margin-bottom: 0;
          letter-spacing: -0.75rem;
        }

        p {
          display: none;
        }
      }
    }

    .mobileBg {
      position: absolute;
      top: 20%;
      left: 0;
      width: 19.5rem;
    }

    .sizeBlock {
      flex: 1;
      height: 69rem;
      max-width: 861px;
      margin-top: 10rem;
      margin-bottom: 50px;
    }

    h2 {
      width: initial;
    }

    .cardWrapper {
      height: 100%;
    }

    .front {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      width: initial;
      height: 100%;

      img {
        width: initial;
        height: 100%;
        transform: translateY(-15px);
      }
    }
    .back {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      width: initial;
      height: 100%;

      img {
        width: initial;
        height: 100%;
        transform: translateY(-15px);
      }
    }

    .descriptionText {
      margin-bottom: 98px;
      font-family: SFTextLight;
      font-weight: 300;
      font-size: 3rem;
      line-height: 3.7rem;
      color: #C4C4C4;
      width: 42%;
      margin-left: 34.9%;
      letter-spacing: -0.17rem;
      color: #C4C4C4;
    }
  }

  .sliderWrapper {
    padding: 0;

    .main {
      padding: 0 0 0 23%;
      margin-bottom: 0;

      .textBlock {
        h1 {
          margin-left: 1.5rem;
        }
      }

      .logo {
        h2 {
          bottom: -3rem;
          right: initial;
          left: 11rem;
          writing-mode: initial;
          transform: initial;
          letter-spacing: -0.05rem;
        }
      }
    }
  }

  .btbBlock {
    position: absolute;
    bottom: 2rem;

    .plusBtn {
      display: none;
    }
  }
}

@media screen and (max-width: 429px) {
  .btn {
    font-size: 22px;
    line-height: 35px;
    width: 170px;
    height: 40px;
    background: $blue-color;
  }

  .shadowWrapperr {
    width: 88vw;
    height: 191.5vw;
  }

  .wrapperPagination {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;

    .customPagination {
      position: absolute;
      display: flex;
      top: -60px;

      & > span {
        margin: 0 2px;
      }
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 24px 0 24px;
  }

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }

  .wrapper {
    padding: 0 0 7vh 0;
    height: 680px;

    .main {
      display: flex;
      align-items: center;
      max-width: 100%;
      width: 100%;
      padding: 0;
      margin-top: 48px;
      margin-bottom: 50px;

      .logo {
        position: relative;
        left: 0;

        img {
          width: 105px;
          margin-left: 5px;
        }

        h2 {
          position: absolute;
          left: 55px;
          top: 77px;
          font-size: 11px;
          line-height: 11px;
          letter-spacing: 0.04em;
        }

        h2.sloganDescription {
          top: 127px;
          left: -12px;
          font-size: 18px;
          line-height: 42px;
          writing-mode: tb-rl;
          transform: rotate(-180deg);
          z-index: 1;
        }
      }

      .logoDescription {
        img {
          margin-left: 5px;
        }
      }

      .textBlock {
        h1 {
          font-size: 34px;
          line-height: 28px;
          margin-left: 7px;
          margin-bottom: 0;
          letter-spacing: -0.1em;
        }

        p {
          display: none;
        }
      }
    }

    .mainDescription {
      margin-bottom: 28px;
    }

    .mobileBg {
      position: absolute;
      top: 152px;
      left: 0;
      width: 115px;
      z-index: -1;
    }

    .sizeBlock {
      flex: 1;
      height: 380px;
      max-height: 380px;
      margin-bottom: 35px;
    }

    h2 {
      width: initial;
    }

    .cardWrapper {
      height: 100%;
    }

    .front {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      width: initial;
      height: 100%;

      img {
        width: initial;
        height: 100%;
        transform: translateY(-15px);
      }
    }
    .back {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      width: initial;
      height: 100%;

      img {
        width: initial;
        height: 100%;
        transform: translateY(-15px);
      }
    }

    .descriptionText {
      margin-left: 119px;
      margin-bottom: 0;
      font-family: SFTextLight;
      font-weight: 300;
      font-size: 18px;
      line-height: 22px;
      color: #C4C4C4;
      letter-spacing: -0.06em;
    }
  }

  .sliderWrapper {
    padding: 0 0 7vh 0;

    .main {
      padding: 0 24px;
    }
  }

  .btbBlock {
    .plusBtn {
      display: none;
    }
  }
}

@media screen and (max-width: 325px) {
  .btn {
    font-size: 16px;
    line-height: 35px;
    width: 152px;
    height: 40px;
  }

  .wrapperPagination {
    .customPagination {
      top: -35px;
    }
  }

  .wrapper {
    height: 550px;

    .main {
      margin-bottom: 22px;

      .logo {
        img {
          width: 88px;
          margin-left: 4px;
        }

        h2 {
          top: 65px;
          left: 47px;
          font-size: 9px;
          line-height: 9px;
        }

        h2.sloganDescription {
          top: 89px;
          left: -8px;
          font-size: 12px;
          line-height: 30px;
        }
      }

      .logoDescription {
        img {
          width: 78px;
          margin-left: 3px;
        }
      }

      .textBlock {
        h1 {
          font-size: 24.7818px;
          line-height: 20px;
          margin-left: 5px;
        }
      }
    }

    .mobileBg {
      top: 115px;
      width: 95px;
    }

    .sizeBlock {
      height: 315px;
      max-height: 315px;
      margin-bottom: 10px;
    }

    .descriptionText {
      width: 186px;
      margin-left: 84px;
      font-size: 15px;
      line-height: 19px;
    }
  }
}
